import { useContext } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { EventContext } from "context";
import { PrimaryTemplate as Layout } from "Layout/Templates";
import { MaxWidthContainer } from "Layout/MaxWidthContainer";

export const PreCampaign = () => {
  const { event } = useContext(EventContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event?.meta?.storageDirectory;

  const { background: preCampaignBackground, heading } = event.preCampaign;

  let backgroundDesktop = `${api}/o/${storageDirectory}%2F${preCampaignBackground?.desktop}?alt=media`;
  let backgroundMobile = `${api}/o/${storageDirectory}%2F${preCampaignBackground?.mobile}?alt=media`;

  return (
    <Layout preCampaign showHeader flexGrow showFooter>
      <PageContent
        backgroundDesktop={backgroundDesktop}
        backgroundMobile={backgroundMobile}
      >
        <h2>{ReactHtmlParser(heading)}</h2>
      </PageContent>
    </Layout>
  );
};

const PageContent = styled(MaxWidthContainer)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #ffbc0d;
  color: #ffffff;
  background-position: center;
  background-size: cover;
  background-image: url(${(props) => props.backgroundMobile});

  @media ${(props) => props.theme.maxMedia.medium} {
    background-image: url(${(props) => props.backgroundDesktop});
  }

  h2 {
    font-size: clamp(18px, 9vw, 64px);
    margin: 50px 0;
  }
`;
